const faviconAndTitle = {
  data() {
    return {
      hostName: '',
    };
  },

  computed: {
    partnerId() {
      const searchParams = new URLSearchParams(window.location.search)
      const paramPid = searchParams.get('pid')
      if (parseInt(paramPid)) {
        return parseInt(paramPid)
      }
      if (parseInt(this.$store.state.user && this.$store.state.user?.user?.partner_id)) {
        return parseInt(this.$store.state.user && this.$store.state.user?.user?.partner_id)
      }
      return ''
    },
    faviconPath() {
      const suffix = this.partnerId ? `-${this.partnerId}` : '';
      return `/favicon-${this.hostName}${suffix}.png`;
    },
    formattedTitle() {
      const baseTitle = `${this.hostName} Dashboard`;
      return this.hostName === 'monitoring'
        ? baseTitle.split(' ').reverse().join(' ')
        : baseTitle.charAt(0).toUpperCase() + baseTitle.slice(1);
    }
  },

  mounted() {
    this.setHostName()
    this.updateFaviconAndTitle()
  },

  methods: {
    setHostName() {
      const splitHostname = window.location.host.split(/[.\*+/:_]/)[0];
      this.hostName = ['d21phl0dsql5vt', 'localhost'].includes(splitHostname)
        ? 'monitoring'
        : splitHostname;
    },
    updateFaviconAndTitle() {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = this.faviconPath;
      document.head.appendChild(link);
      document.title = this.formattedTitle;
    }
  }
}

export default faviconAndTitle
