import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VeeValidate from 'vee-validate'
import '@/sass/index.scss'
import './permission' //


Vue.config.productionTip = false
Vue.use(VeeValidate);

const savedColor = localStorage.getItem('theme-color')
if (savedColor) {
  document.documentElement.style.setProperty('--theme-color', savedColor)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
